import React, { useEffect, useState } from "react";
import {
  Stack,
  Button,
  Fade,
  Box,
  IconButton,
  Modal,
  Grid,
  TextField,
} from "@mui/material";
import DoneImage from "../Images/doneicon.png";
import { useNavigate } from "react-router-dom";
import { checkUserLoggedIn } from "../shared/commonfunctions";
import styles from "../pages/Styles/Congratulation.module.scss";
import image from "../Components/assets/Images/congralutionsImage.svg";
import KRAFetchedDetails from "./KRAFetchedDetails";
import { stageDetailsAPICall, startOverAPICall } from "../shared/APICalls";
import PDFViewerIframe from "../Components/PDFIframe/PDFWithIframe";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import facebookImage from "../Components/assets/Images/Facebook.svg";
import twitterImage from "../Components/assets/Images/twitter.svg";
import linkedinImage from "../Components/assets/Images/linkedin.svg";
import whatsappImage from "../Components/assets/Images/Whatsappnew.svg";
import doneimage from "../Components/assets/Images/donetickimage.svg";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CopiedSnackBar from "../Components/SnackBars/CopiedSnackBar";
import StageLayout from "../Layouts/StageLayout";
import BackDrop from "../Components/BackDrop";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vW",
  height: "90vH",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  padding: "0px 0px 0px 5px",
};
const containerStyle = {
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
};

export default function CongratulationsPage() {
  const navigate = useNavigate();
  const [noEditableData, setnoEditableData] = useState({
    name: "",
    gender: "",
    dob: "",
    address: "",
    client_code: "",
    email: "",
    url: "",
  });
  const [showModal, setshowModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<any>("");
  const [open, setOpen] = React.useState(true);
  const [copied, setCopied] = useState(false);
  const [clientCode, setClientCode] = useState("");
  const [message, setmessage] = useState("");
  const [completedStages, setCompletedStages] = useState<any>();
  const [backButton, setBackButton] = useState("");
  const [backdropLoading, setbackdropLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    // checkUserLoggedIn(navigate);

    let objNew: any = {
      stage: "congratulations",
    };
    setbackdropLoading(true);
    stageDetailsAPICall(objNew)
      .then((response: any) => {
        const responseData = response.data;
        setbackdropLoading(false);
        // console.log("responseData", responseData.data.data);

        if (responseData.data) {
          setClientCode(responseData.data.data.client_code);
          setnoEditableData(responseData.data.data);
        }

        if (responseData.data.data.url) {
          setPdfUrl(responseData.data.data.url);
        }
      })
      .catch((error: any) => {
        setbackdropLoading(false);
      });
  }, []);

  const handlePreviewClick = () => {
    setshowModal(true);
  };

  const handlePreviewClose = () => {
    setshowModal(false);
  };

  const baseUrl = window.location.href.split("/congratulations")[0];
  const urlToShare = `${baseUrl}?c=${clientCode}`;

  return (
    <>
      {backdropLoading && (
        <div style={{ height: "100vh", position: "absolute" }}>
          <BackDrop />
        </div>
      )}
      <div className={styles.desktopProgress}>
        <StageLayout
          completedStages={completedStages}
          stageName={"Esign Section"}
          navigatePath={"/cameramodule"}
          esignBackButtonEnable={backButton}
        />
      </div>
      <div className={styles.mainWrapperTop}>
        <div className={styles.mobileProgress}>
          <StageLayout
            completedStages={completedStages}
            stageName={"Esign Section"}
            navigatePath={"/cameramodule"}
            esignBackButtonEnable={backButton}
          />
        </div>
        <div className={styles.wrapper}>
          <div className={styles.leftContainer}>
            <div className={styles.headerSection}>
              <p className={styles.signup}>
                Congratulations! You will receive a link on your email shortly
                to e-sign the DDPI (Demat Debit and Pledge Instruction), please
                complete the process.
              </p>
            </div>
            <Stack height={"max-content"}>
              <p className={styles.header}>User Details</p>
              <div className={styles.containerTextFeilds}>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 5 }}
                >
                  <Grid item xs={12} sm={6}>
                    <p className={styles.textfieldLabel}>Name </p>
                    <TextField
                      fullWidth
                      size="small"
                      id="outlined-basic"
                      placeholder="Name "
                      variant="outlined"
                      value={noEditableData.name}
                      inputProps={{
                        sx: {
                          background: "#F2F2F6",

                          borderRadius: "8px",
                          "&::placeholder": {
                            paddingLeft: "10px",
                          },

                          paddingLeft: "10px",
                        },
                      }}
                      sx={{
                        fieldset: { borderColor: "transparent" },
                        width: { xs: "100%", sm: "100%" },
                        input: {
                          color: "#344054",

                          fontSize: "14px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p className={styles.textfieldLabel}>Gender</p>
                    <TextField
                      fullWidth
                      size="small"
                      id="outlined-basic"
                      placeholder="Gender"
                      variant="outlined"
                      value={noEditableData.gender}
                      inputProps={{
                        sx: {
                          background: "#F2F2F6",
                          borderRadius: "8px",
                          "&::placeholder": {
                            paddingLeft: "10px",
                          },

                          paddingLeft: "10px",
                        },
                      }}
                      sx={{
                        fieldset: { borderColor: "transparent" },
                        width: { xs: "100%", sm: "100%" },
                        input: {
                          color: "#344054",
                          fontSize: "14px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p className={styles.textfieldLabel}>Date of Birth</p>
                    <TextField
                      fullWidth
                      size="small"
                      id="outlined-basic"
                      placeholder="Date of Birth"
                      variant="outlined"
                      value={noEditableData.dob}
                      inputProps={{
                        sx: {
                          background: "#F2F2F6",
                          borderRadius: "8px",
                          "&::placeholder": {
                            paddingLeft: "10px",
                          },

                          paddingLeft: "10px",
                        },
                      }}
                      sx={{
                        fieldset: { borderColor: "transparent" },
                        width: { xs: "100%", sm: "100%" },
                        input: {
                          color: "#344054",
                          fontSize: "14px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p className={styles.textfieldLabel}>Address</p>
                    <TextField
                      fullWidth
                      size="small"
                      id="outlined-basic"
                      placeholder="Address"
                      variant="outlined"
                      value={noEditableData.address}
                      inputProps={{
                        sx: {
                          background: "#F2F2F6",
                          borderRadius: "8px",
                          "&::placeholder": {
                            paddingLeft: "10px",
                          },

                          paddingLeft: "10px",
                        },
                      }}
                      sx={{
                        fieldset: { borderColor: "transparent" },
                        width: { xs: "100%", sm: "100%" },
                        input: {
                          color: "#344054",
                          fontSize: "14px",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </Stack>
          </div>
          <div className={styles.rightContainer}>
            <div className={styles.imagediv}>
              <img src={image} className={styles.image} />
            </div>
          </div>
        </div>
        <div className={styles.shareMainWrapper}>
          <div className={styles.viewDocButton}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                borderRadius: "8px",

                border: "none",
                boxShadow: "none",
                width: { xs: "100%", sm: "max-content" },
              }}
              className={styles.previewButton}
              id="aadharOpenLinkButton"
              onClick={handlePreviewClick}
            >
              View E-sign Document
            </Button>
          </div>
          <div className={styles.shareWrapper}>
            <div className={styles.referralSection}>
              <div className={styles.copyreferralMainSection}>
                <p className={styles.referraltext}>Your Referral Link:</p>
                <div className={styles.copyreferral}>
                  <div className={styles.copyreferralTextFeild}>
                    <div className={styles.copyreferralurlText}>
                      {urlToShare}
                    </div>
                    <CopyToClipboard
                      text={urlToShare}
                      onCopy={() => {
                        setCopied(true);
                        setmessage("Copied to Clipboard");
                        setTimeout(() => {
                          setmessage("");
                        }, 4000);
                      }}
                    >
                      <Button
                        className={styles.copyreferralButton}
                        variant="contained"
                        sx={{
                          height: "35px",
                          borderRadius: "10px",
                          width: "50px",
                        }}
                      >
                        Copy
                      </Button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.imageSection}>
              <FacebookShareButton url={urlToShare}>
                <img src={facebookImage} className={styles.shareimageicon} />
              </FacebookShareButton>
              <LinkedinShareButton url={urlToShare}>
                <img src={linkedinImage} className={styles.shareimageicon} />
              </LinkedinShareButton>
              <TwitterShareButton url={urlToShare}>
                <img src={twitterImage} className={styles.shareimageicon} />
              </TwitterShareButton>
              <WhatsappShareButton url={urlToShare}>
                <img src={whatsappImage} className={styles.shareimageicon} />
              </WhatsappShareButton>
            </div>
          </div> 
        </div>
        <CopiedSnackBar message={message} />

        {showModal && (
          <Stack>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              closeAfterTransition
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={open}>
                <Box sx={style}>
                  {/* <PdfModal
                // url={"https://arxiv.org/pdf/quant-ph/0410100.pdf"}
                url={documentURL}
              /> */}
                  <Stack
                    alignItems={"flex-end"}
                    marginRight={"10px"}
                    marginTop={"5px"}
                  >
                    <IconButton onClick={handlePreviewClose}>
                      <HighlightOffIcon />
                    </IconButton>
                  </Stack>
                  <div className={styles.pdfMobilePreview}>
                    {/* <PdfModal url={pdfUrl} /> */}
                    <a
                      href={pdfUrl}
                      download="Esign-PDF-document"
                      target="_blank"
                      rel="noreferrer"
                      className={styles.previewdownlaodesign}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          background: "#1976d2",
                          "&:hover": { background: "#1976d2" },
                        }}
                      >
                        View Esign Document
                      </Button>
                    </a>
                    <PDFViewerIframe urldata={pdfUrl} />
                  </div>
                  <div className={styles.pdfDesktopPreview}>
                    <PDFViewerIframe urldata={pdfUrl} />
                  </div>
                </Box>
              </Fade>
            </Modal>
          </Stack>
        )}
      </div>
    </>
  );
}
